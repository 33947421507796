var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Container, Text } from "pixi.js";
import Game from "../starter";
import { Action, CONSTANTS, Helpers, SpinAction } from "lama-games-engine";
import { CandyButton } from './buttons/CandyButton';
import { NineSliceButton } from './buttons/NineSliceButton';
class ActionsGameContainer extends Container {
}
class SlideGameContainer extends Container {
}
export class Actions {
    constructor() {
        this.speedContainer = new Container();
        this.betActions = {};
        this.infoContainer = {};
        this.freeSpinsCounter = 0;
        this.actionsContainer = new ActionsGameContainer();
        this.sliderContainer = new SlideGameContainer();
        this.loadActions();
        this.loadSlider();
        Game.Engine.Emitter.on(CONSTANTS.APPLICATION_EVENTS.GAME_DATA.UPDATE, this.onGameDataUpdate.bind(this));
        Game.Engine.Emitter.on(CONSTANTS.APPLICATION_EVENTS.ACTION_EVENTS, this.onAction.bind(this));
        Game.Engine.Emitter.on(CONSTANTS.APPLICATION_EVENTS.BASE_EVENTS.BALANCE_UPDATED, this.onWin.bind(this));
        Game.Engine.Emitter.on(CONSTANTS.APPLICATION_EVENTS.GLOBAL_SOCKET_EVENT, this.onSpin.bind(this));
        Game.Engine.Emitter.on(CONSTANTS.APPLICATION_EVENTS.GAME_MODE_CHANGE, this.onModeChange.bind(this));
    }
    onModeChange({ mode }) {
        if (mode === 'free-spins') {
            this.freeSpinsCounter += 10;
            this.takeButton.setLabel(this.freeSpinsCounter.toString());
        }
        if (mode === 'idle') {
            this.freeSpinsCounter -= 1;
            if (this.freeSpinsCounter < 0) {
                this.takeButton.setLabel('BUY BONUS');
                this.freeSpinsCounter = 0;
            }
            else {
                this.takeButton.setLabel(this.freeSpinsCounter.toString());
            }
        }
    }
    onSpin(action) {
        if (action.action === CONSTANTS.APPLICATION_EVENTS.SOCKET_EVENTS.SPIN) {
            clearTimeout(this.winLinesTimeout);
            this.clearWinLines();
        }
    }
    clearWinLines() {
        return __awaiter(this, void 0, void 0, function* () {
            return new Promise(resolve => {
                if (this.winLines && this.winLines.children.length > 1) {
                    this.winLines.removeChildren(1);
                    setTimeout(() => {
                        resolve(undefined);
                    }, 300);
                }
            });
        });
    }
    showLine(line, symbol, prize) {
        return new Promise(resolve => {
            this.presentWinLines(line, symbol, prize);
            this.winLinesTimeout = setTimeout(() => __awaiter(this, void 0, void 0, function* () {
                yield this.clearWinLines();
                resolve(undefined);
            }), 3000);
        });
    }
    detectWin(data, hasWin = false) {
        return __awaiter(this, void 0, void 0, function* () {
            if (!hasWin)
                return;
            const winLines = Object.keys(data.reels.payouts).filter(key => {
                return Object.values(data.reels.payouts[key]).length > 0;
            });
            const winnings = winLines.map(line => {
                const symbol = Object.keys(data.reels.payouts[line])[0];
                const symbolImage = Game.Engine.SETTINGS.GAME_SYMBOLS_MAP[symbol];
                const prize = Object.values(data.reels.payouts[line])[0];
                return { symbol: symbolImage, prize, line: Number(line) + 1 };
            });
            for (const line of winnings) {
                yield this.showLine(`${line.line}`, line.symbol, `${Number(line.prize).toFixed(2)}`);
            }
        });
    }
    onWin(data) {
        this.detectWin(data.data, data.win > 0);
    }
    onAction(event) {
        const current = Game.Engine.Application.gameData.bet;
        if (event.action === CONSTANTS.BUTTON_ACTIONS.BET_MINUS) {
            const newValue = current - Game.Engine.SETTINGS.GAME_BET_STEP;
            Game.Engine.Emitter.emit(CONSTANTS.APPLICATION_EVENTS.ACTION_EVENTS, { action: CONSTANTS.BUTTON_ACTIONS.BET_CHANGE, payload: newValue > Game.Engine.SETTINGS.GAME_BET_STEP ? newValue : Game.Engine.SETTINGS.GAME_BET_STEP });
        }
        if (event.action === CONSTANTS.BUTTON_ACTIONS.BET_PLUS) {
            const newValue = current + Game.Engine.SETTINGS.GAME_BET_STEP;
            Game.Engine.Emitter.emit(CONSTANTS.APPLICATION_EVENTS.ACTION_EVENTS, { action: CONSTANTS.BUTTON_ACTIONS.BET_CHANGE, payload: newValue });
        }
    }
    loadActions() {
        const spinButton = new SpinAction('/textures/animated-spin.json');
        this.spinButton = spinButton;
        Game.Engine.ACTIONS_CONTAINER.actions.push(spinButton);
        this.actionsContainer.addChild(spinButton.button.sprite);
        spinButton.button.sprite.animationSpeed = 0.8;
        spinButton.button.sprite.position.set(spinButton.button.sprite.width / 2, spinButton.button.sprite.height / 2);
        this.spinButton.button.setOptions({
            loopStart: 40,
            loopEnd: 40
        });
        this.sliceButton = new CandyButton({
            width: 420,
            height: 100,
            label: 'SHAKE',
            enabled: false,
            clickHandler: () => {
                Game.Engine.Emitter.emit(CONSTANTS.APPLICATION_EVENTS.ACTION_EVENTS, { action: CONSTANTS.BUTTON_ACTIONS.SLICE });
            }
        });
        this.actionsContainer.addChild(this.sliceButton.container);
        this.sliceButton.position.set(this.sliceButton.width / 2, this.sliceButton.height / 2);
        Game.Engine.ACTIONS_CONTAINER.actions.push(this.sliceButton.toAction(CONSTANTS.BUTTON_ACTIONS.SLICE));
        this.takeButton = new NineSliceButton({
            width: 420,
            height: 100,
            label: 'BUY BONUS',
            enabled: true,
            clickHandler: () => {
                Game.Engine.Emitter.emit(CONSTANTS.APPLICATION_EVENTS.ACTION_EVENTS, { action: CONSTANTS.BUTTON_ACTIONS.FREE_SPINS });
            }
        });
        this.actionsContainer.addChild(this.takeButton.container);
        this.takeButton.position.set(this.takeButton.width / 2, this.takeButton.height / 2);
        Game.Engine.ACTIONS_CONTAINER.actions.push(this.takeButton.toAction(CONSTANTS.BUTTON_ACTIONS.TAKE));
        const autospinButton = new Action(CONSTANTS.BUTTON_ACTIONS.AUTO_SPIN, 'auto-btn.png');
        Game.Engine.ACTIONS_CONTAINER.actions.push(autospinButton);
        this.autospinButton = autospinButton;
        this.actionsContainer.addChild(autospinButton.button.sprite);
        autospinButton.button.sprite.position.set(autospinButton.button.sprite.width / 2, autospinButton.button.sprite.height / 2);
        const speedButton = new Action(CONSTANTS.BUTTON_ACTIONS.SPEED, 'speed-btn.png', 'speed_arrow.png');
        speedButton.icon.x = speedButton.button.sprite.x;
        speedButton.icon.y = speedButton.button.sprite.y;
        speedButton.animateIcon(94, 0);
        Game.Engine.ACTIONS_CONTAINER.actions.push(speedButton);
        this.speedButton = speedButton;
        speedButton.button.sprite.position.set(speedButton.button.sprite.width / 2, speedButton.button.sprite.height / 2);
        speedButton.icon.position.set(speedButton.icon.width / 2, speedButton.icon.height / 2);
        this.speedContainer.addChild(speedButton.button.sprite);
        this.speedContainer.addChild(speedButton.icon);
        this.speedContainer.pivot.set(speedButton.button.sprite.width / 2, speedButton.button.sprite.height / 2);
        this.speedContainer.position.set(speedButton.button.sprite.width / 2, speedButton.button.sprite.height / 2);
        this.actionsContainer.addChild(this.speedContainer);
    }
    presentWinLines(line, symbol, prize) {
        if (Game.Engine.SETTINGS.GAME_ANIMATION_TYPE === 'cascade')
            return;
        const placeholder = this.winLines.getChildAt(0).getLocalBounds();
        const winLineText = new Text(`Line ${line}`, {
            fontFamily: 'Montserrat',
            fontSize: 20,
            fontWeight: 'bold',
            fill: 0x63452A,
            align: 'center',
            stroke: "#FFFFFF",
            strokeThickness: 2,
        });
        winLineText.anchor.set(0.5);
        winLineText.x = (this.sliderContainer.width / 2) - (placeholder.width / 2) + (winLineText.width / 2);
        winLineText.y = (placeholder.height / 2) + placeholder.y;
        this.winLines.addChild(winLineText);
        const winSymbols = [symbol, symbol, symbol];
        const leftOffset = 12;
        winSymbols.forEach((s, index) => {
            const image = Helpers.animatedSpriteFromFrames(`/textures/animations/${s}.json`);
            const size = placeholder.height - 10;
            image.width = size;
            image.height = size;
            image.anchor.set(0, 0.5);
            const firstChildOffset = 12;
            image.x = winLineText.x + (winLineText.width / 2) + (index * size) - (leftOffset * index) + firstChildOffset;
            image.y = (placeholder.height / 2) + placeholder.y;
            this.winLines.addChild(image);
        });
        const winValueText = new Text(prize, {
            fontFamily: 'Montserrat',
            fontSize: 20,
            fontWeight: 'bold',
            fill: 0x63452A,
            align: 'center',
            stroke: "#FFFFFF",
            strokeThickness: 2,
        });
        winValueText.anchor.set(0, 0.5);
        winValueText.x = placeholder.width + placeholder.x - winValueText.width;
        winValueText.y = (placeholder.height / 2) + placeholder.y;
        this.winLines.addChild(winValueText);
    }
    loadSlider() {
        const betActionsContainer = new Container();
        const betContainer = new Container();
        const betImage = Helpers.getSpriteFromTextureURL('bet.png');
        //betImage.y = TOP_ACTIONS_OFFSET - (betImage.height/2)
        const betText = new Text(Game.Engine.Application.gameData.bet.toFixed(2), {
            fontFamily: 'Montserrat',
            fontSize: 80,
            "fill": [
                "#fdc831",
                "#f79600",
            ],
            "fillGradientStops": [
                0,
                1
            ],
            fillGradientType: 0,
            stroke: "#c16900",
            strokeThickness: 2,
            dropShadow: true,
            dropShadowAlpha: 0.5,
            dropShadowAngle: 45,
            dropShadowBlur: 3,
            dropShadowColor: "#000000",
            dropShadowDistance: 3,
            trim: true
        });
        this.bet = betText;
        betText.anchor.set(0.5);
        betText.x = betImage.width / 2;
        betText.y = betImage.height / 2;
        betContainer.addChild(betImage);
        betContainer.addChild(betText);
        betActionsContainer.addChild(betContainer);
        this.infoContainer['bet'] = betContainer;
        const betPlus = new Action(CONSTANTS.BUTTON_ACTIONS.BET_PLUS, 'plus-btn.png');
        betActionsContainer.addChild(betPlus.button.sprite);
        this.betActions['plus'] = betPlus;
        const betMinus = new Action(CONSTANTS.BUTTON_ACTIONS.BET_MINUS, 'minus-btn.png');
        betActionsContainer.addChild(betMinus.button.sprite);
        this.betActions['minus'] = betMinus;
        this.sliderContainer.addChild(betActionsContainer);
        this.infoContainer['bet'].pivot.set(this.infoContainer['bet'].width / 2, this.infoContainer['bet'].height / 2);
        const winContainer = new Container();
        const winImage = Helpers.getSpriteFromTextureURL('win.png');
        const winText = new Text(Game.Engine.Application.gameData.lastWin.toFixed(2), {
            fontFamily: 'Montserrat',
            fontSize: 50,
            fill: '#315603',
            stroke: "#042800",
            strokeThickness: 2,
            dropShadow: true,
            dropShadowAlpha: 0.3,
            dropShadowAngle: 45,
            dropShadowBlur: 3,
            dropShadowColor: "#FFFFFF",
            dropShadowDistance: 3,
            trim: true,
        });
        this.win = winText;
        winText.anchor.set(0.5);
        winText.x = winImage.width / 2 + 55;
        winText.y = winImage.height / 4 + 35;
        winContainer.addChild(winImage);
        winContainer.addChild(winText);
        winContainer.x = betPlus.button.sprite.x + 32;
        betActionsContainer.addChild(winContainer);
        this.sliderContainer.addChild(winContainer);
        this.infoContainer['win'] = winContainer;
        const balanceContainer = new Container();
        const balanceImage = Helpers.getSpriteFromTextureURL('balance.png');
        const balanceText = new Text(Game.Engine.Application.gameData.balance.toFixed(2), {
            fontFamily: 'Montserrat',
            fontSize: 48,
            fill: '#54005d',
            stroke: "#37003f",
            strokeThickness: 2,
            dropShadow: true,
            dropShadowAlpha: 0.3,
            dropShadowAngle: 45,
            dropShadowBlur: 3,
            dropShadowColor: "#FFFFFF",
            dropShadowDistance: 3,
            trim: true
        });
        this.balance = balanceText;
        balanceText.anchor.set(0.5);
        balanceText.x = balanceImage.width / 2 + 55;
        balanceText.y = balanceImage.height / 4 + 35;
        balanceContainer.addChild(balanceImage);
        balanceContainer.addChild(balanceText);
        balanceContainer.pivot.set(balanceContainer.width / 2, balanceContainer.height / 2);
        balanceContainer.x = 0 - (balanceContainer.width / 2);
        // balanceContainer.y = this.infoContainer['bet'].height + 48
        winContainer.pivot.set(winContainer.width / 2, winContainer.height / 2);
        winContainer.x = (winContainer.width / 2);
        // winContainer.y = this.infoContainer['bet'].height + 48
        this.sliderContainer.addChild(balanceContainer);
        this.infoContainer['bet'].y = winContainer.height - 62;
        this.betActions['minus'].button.sprite.position.x = 0 - (this.infoContainer['bet'].width / 2) - (this.betActions['minus'].button.sprite.width / 2);
        this.betActions['plus'].button.sprite.position.x = (this.infoContainer['bet'].width / 2) + (this.betActions['plus'].button.sprite.width / 2);
        this.betActions['plus'].button.sprite.position.y = this.infoContainer['bet'].y;
        this.betActions['minus'].button.sprite.position.y = this.infoContainer['bet'].y;
        this.infoContainer['balance'] = balanceContainer;
        if (!window.PLATFORM.isMobile) {
            balanceContainer.x = this.betActions['minus'].button.sprite.x - (this.infoContainer['bet'].width / 2) - 24;
            balanceContainer.y = this.infoContainer['bet'].y + 18;
            // winContainer
            winContainer.x = this.betActions['plus'].button.sprite.x + (this.infoContainer['bet'].width / 2) + 24;
            winContainer.y = this.infoContainer['bet'].y + 18;
        }
    }
    onBetChange(action, value) {
        Game.Engine.Emitter.emit(CONSTANTS.APPLICATION_EVENTS.ACTION_EVENTS, { action, payload: value });
    }
    onGameDataUpdate() {
        this.setBalance(Game.Engine.Application.gameData.balance);
        this.setWin(Game.Engine.Application.gameData.totalWin);
        this.setBet(Game.Engine.Application.gameData.bet);
    }
    setBalance(value) {
        this.balance.text = value.toFixed(2);
    }
    setBet(value) {
        this.bet.text = value.toFixed(2);
    }
    setWin(value) {
        this.win.text = (value).toFixed(2);
    }
}
