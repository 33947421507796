import { Helpers, SizedContainer } from 'lama-games-engine';
import { Container, Text, TextStyle } from 'pixi.js';
import Game from '../starter';
import { H3 } from './Headings';
const paragraphStyle = new TextStyle({
    fill: "#ffffff",
    fontFamily: 'Poppins',
    fontWeight: 'normal',
    fontSize: 32,
    align: "center",
    wordWrap: true
});
const values = {
    "watermelon": [
        '12 - 30 $100.00',
        '10 - 11 $50.00',
        '8 - 9 $20.00'
    ],
    "melon": [
        '12 - 30 $100.00',
        '10 - 11 $50.00',
        '8 - 9 $20.00'
    ],
    "orange": [
        '12 - 30 $100.00',
        '10 - 11 $50.00',
        '8 - 9 $20.00'
    ],
    "pomegranate": [
        '12 - 30 $100.00',
        '10 - 11 $50.00',
        '8 - 9 $20.00'
    ],
    "grape": [
        '12 - 30 $100.00',
        '10 - 11 $50.00',
        '8 - 9 $20.00'
    ],
    "fig": [
        '12 - 30 $100.00',
        '10 - 11 $50.00',
        '8 - 9 $20.00'
    ],
    "pyramid": [
        '12 - 30 $100.00',
        '10 - 11 $50.00',
        '8 - 9 $20.00'
    ],
    "scarabei": [
        '12 - 30 $100.00',
        '10 - 11 $50.00',
        '8 - 9 $20.00'
    ]
};
export class GameRulesFour extends SizedContainer {
    constructor(width, height) {
        super(width, height, false);
        const scatter = new H3(`Game rules`, { fill: "#F5BD46" });
        scatter.anchor.set(0.5, 0);
        scatter.position.set(width / 2, -6);
        this.addChild(scatter);
        const scatterText = new Text('Symbols pay anywhere on the screen. The total number of the same symbol ' +
            'on the screen at the end of a spin determines the value of the win', Object.assign(Object.assign({}, paragraphStyle), { fontSize: 16, wordWrapWidth: width - 100 }));
        scatterText.anchor.set(0.5, 0);
        scatterText.position.set(width / 2, scatter.y + scatter.height);
        this.addChild(scatterText);
        const symbols = new Container();
        symbols.position.set(0, scatterText.y + scatterText.height + 20);
        this.addChild(symbols);
        Object
            .keys(Game.Engine.SETTINGS.GAME_SYMBOLS_MAP)
            .forEach((key, index) => {
            const symbolName = Game.Engine.SETTINGS.GAME_SYMBOLS_MAP[key];
            const animatedSprite = Helpers.animatedSpriteFromFrames(`/textures/animations/${symbolName}.json`);
            const scale = Math.min(96 / animatedSprite.width, 96 / animatedSprite.height);
            animatedSprite.loop = false;
            animatedSprite.animationSpeed = 0;
            animatedSprite.scale.x = animatedSprite.scale.y = scale;
            const symbolText = new Text(
            //@ts-ignore
            values[symbolName].join("\n"), Object.assign(Object.assign({}, paragraphStyle), { fontSize: 16, align: "left", wordWrap: false }));
            const symbol = new SizedContainer(this.width / 4, animatedSprite.height + symbolText.height + 40, false);
            symbol.position.set((index % 4) * this.width / 4, Math.floor(index / 4) * (animatedSprite.height + symbolText.height + 40));
            symbol.addChild(animatedSprite);
            symbol.addChild(symbolText);
            animatedSprite.position.set(symbol.width / 2 - animatedSprite.width / 2, 0);
            symbolText.position.set(symbol.width / 2 - symbolText.width / 2, animatedSprite.y + animatedSprite.height);
            symbols.addChild(symbol);
        });
    }
}
