import { CustomEase } from "gsap/CustomEase";
import { CustomWiggle } from "gsap/CustomWiggle";
import { CONSTANTS, engineGsap, Helpers, Reel, } from "lama-games-engine";
import { Container, NineSlicePlane, Sprite, Text, Texture } from "pixi.js";
import Game from "../starter";
import { Multiplier, MultipliersContainer, RectangleMask, ReelsViewContainer, } from "./Containers";
engineGsap.registerPlugin(CustomEase, CustomWiggle);
CustomWiggle.create("myWiggle", {
    wiggles: 8,
    type: "uniform",
});
export class ReelsContainer extends Container {
    constructor() {
        super();
        this.reelsView = new ReelsViewContainer();
        this.multipliersView = new MultipliersContainer();
        this.multipliers = [];
        this.multiplierContainers = [];
        this.zIndex = 1000;
        this.loadStageView().createReels().createMultipliers().initListeners();
        // this.scale.set()
    }
    initListeners() {
        Game.Engine.Emitter.on(CONSTANTS.APPLICATION_EVENTS.ACTION_EVENTS, ({ action, payload }) => {
            switch (action) {
                case CONSTANTS.BUTTON_ACTIONS.SLICE:
                    this.resizeScene();
                    this.wiggleSymbols();
                    break;
            }
        });
        return this;
    }
    getStageBackgroundKey() {
        if (window.PLATFORM.isLandscape && window.PLATFORM.isMobile) {
            return "scene-landscape";
        }
        if (!window.PLATFORM.isMobile) {
            return "scene-desktop";
        }
        return "scene";
    }
    loadStageView() {
        this.texture = new Texture(Helpers.getAsset(this.getStageBackgroundKey()));
        const scene = new NineSlicePlane(this.texture, this.texture.width / 2, 525, this.texture.width / 2, this.texture.height - 525);
        this.scene = scene;
        this.scene.width = this.texture.width;
        this.scene.height = this.texture.height;
        // END
        //setTimeout(this.resizeScene.bind(this), 3000)
        this.addChild(scene);
        return this;
    }
    resizeScene() {
        const offset = Game.Engine.REELS[0].symbols[0].container.height;
        // @ts-ignore
        const sweets = Game.Engine.Navigator.currentScreen.sweets;
        // @ts-ignore
        const logo = Game.Engine.Navigator.currentScreen.logo;
        engineGsap.to(this.scene, {
            pixi: {
                // tilePositionX: -1200,
                height: this.scene.height + offset,
                y: this.scene.y - offset,
            },
            duration: 0.33,
            ease: "power3.out",
            repeat: 0,
        });
        engineGsap.to(sweets, {
            pixi: {
                y: sweets.y - offset,
            },
            duration: 0.33,
            ease: "power3.out",
            repeat: 0,
        });
        if (window.PLATFORM.isMobile && !window.PLATFORM.isLandscape) {
            engineGsap.to(logo, {
                pixi: {
                    y: logo.y - offset,
                },
                duration: 0.33,
                ease: "power3.out",
                repeat: 0,
            });
        }
    }
    resizeBackScene() {
        const offset = Game.Engine.REELS[0].symbols[0].container.height;
        // @ts-ignore
        const sweets = Game.Engine.Navigator.currentScreen.sweets;
        // @ts-ignore
        const logo = Game.Engine.Navigator.currentScreen.logo;
        engineGsap.to(this.scene, {
            pixi: {
                // tilePositionX: -1200,
                height: this.scene.height - offset,
                y: this.scene.y + offset,
            },
            duration: 0.2,
            ease: "power3.out",
            repeat: 0,
        });
        engineGsap.to(sweets, {
            pixi: {
                y: sweets.y + offset,
            },
            duration: 0.33,
            ease: "power3.out",
            repeat: 0,
        });
        if (window.PLATFORM.isMobile && !window.PLATFORM.isLandscape) {
            engineGsap.to(logo, {
                pixi: {
                    y: logo.y + offset,
                },
                duration: 0.33,
                ease: "power3.out",
                repeat: 0,
            });
        }
    }
    wiggleSymbols() {
        const timeline = engineGsap.timeline({
            onComplete: () => {
                //console.log(`--- All symbols wiggles completed`);
            },
        });
        Game.Engine.REELS.forEach((reel) => {
            reel.symbols.forEach((symbol) => {
                const wiggleTween = engineGsap.to(symbol.container, 0.5, {
                    pixi: {
                        x: 5,
                    },
                    ease: "myWiggle",
                    paused: false,
                    onComplete: () => {
                        //console.log(`--- Single symbol wiggle completed`);
                    },
                });
                timeline.add(wiggleTween, "<");
            });
        });
    }
    getReelSize() {
        if (window.PLATFORM.isMobile && window.PLATFORM.isLandscape) {
            return this.scene.height / Game.Engine.SETTINGS.GAME_REELS;
        }
        if (window.PLATFORM.isMobile && window.PLATFORM.isPortrait) {
            return this.scene.width / Game.Engine.SETTINGS.GAME_REELS;
        }
        if (this.scene.height < this.scene.width) {
            return this.scene.height / Game.Engine.SETTINGS.GAME_REELS;
        }
        return this.scene.width / Game.Engine.SETTINGS.GAME_REELS;
    }
    createReels() {
        this.addChild(this.reelsView);
        const size = this.getReelSize();
        const rWidth = this.scene.width;
        const rHeight = size * Game.Engine.SETTINGS.GAME_SYMBOLS_PER_REEL;
        for (let i = 0; i < Number(Game.Engine.SETTINGS.GAME_REELS); i++) {
            const reel = new Reel(i, rWidth, rHeight);
            Game.Engine.REELS.push(reel);
        }
        Game.Engine.REELS.forEach((reel) => {
            this.reelsView.addChild(reel.container);
            reel.presentDividers();
        });
        this.reelsView.scale.set(0.82);
        this.reelsView.position.x = this.scene.width / 2 - this.reelsView.width / 2;
        this.reelsView.position.y += 120;
        const mask = new RectangleMask(rWidth, rHeight);
        this.reelsView.addChild(mask);
        this.reelsView.mask = mask;
        return this;
    }
    createMultipliers() {
        Game.Engine.REELS.forEach((reel, reelIndex) => {
            reel.symbols.forEach((symbol, symbolIndex) => {
                const container = new Multiplier(reelIndex, symbolIndex);
                const multiplierSprite = new Sprite(Texture.WHITE);
                multiplierSprite.alpha = 0;
                multiplierSprite.width = symbol.config.size;
                multiplierSprite.height = symbol.config.size;
                multiplierSprite.position.set(reel.container.x, symbol.container.y);
                container.addChild(multiplierSprite);
                const multiplierText = new Text("", {
                    fontFamily: "Montserrat",
                    fontSize: 120,
                    fill: "#fed007",
                    trim: true,
                    miterLimit: 1,
                    stroke: "#0d0c0c",
                    strokeThickness: 2,
                });
                multiplierText.anchor.set(0.5);
                multiplierText.alpha = 0.9;
                multiplierText.position.set(reel.container.x + multiplierSprite.width / 2, symbol.container.y + multiplierSprite.height / 2);
                container.addChild(multiplierText);
                container.setText(multiplierText);
                container.setSprite(multiplierSprite);
                this.multiplierContainers.push(container);
                this.multipliersView.addChild(container);
            });
        });
        this.sortableChildren = true;
        this.multipliersView.zIndex = 1;
        this.reelsView.zIndex = 2;
        this.multipliersView.scale.set(0.82);
        this.multipliersView.position.x =
            this.scene.width / 2 - this.reelsView.width / 2;
        this.multipliersView.position.y += 120;
        this.addChild(this.multipliersView);
        return this;
    }
    setMultipliersPosition() {
        Game.Engine.REELS.forEach((reel, reelIndex) => {
            reel.symbols.forEach((symbol, symbolIndex) => {
                const multiplier = this.multiplierContainers[reelIndex + symbolIndex];
                multiplier.sprite.width = symbol.config.size;
                multiplier.sprite.height = symbol.config.size;
                multiplier.sprite.position.set(reel.container.x, symbol.container.y);
                multiplier.text.position.set(reel.container.x + multiplier.sprite.width / 2, symbol.container.y + multiplier.sprite.height / 2);
            });
        });
        this.multipliersView.position.x =
            this.scene.width / 2 - this.reelsView.width / 2;
    }
    setMultipliers(data) {
        data.forEach((reel, reelIndex) => {
            reel.forEach((symbol, symbolIndex) => {
                const multiplier = this.getMultiplier(reelIndex, symbolIndex);
                multiplier === null || multiplier === void 0 ? void 0 : multiplier.setMultiplier(symbol);
            });
        });
    }
    clearMultipliers() {
        this.multiplierContainers.forEach((multiplier) => {
            multiplier.setMultiplier(0);
        });
    }
    getMultiplier(x, y) {
        return this.multiplierContainers.find((m) => {
            return m.reel === x && m.symbol === y;
        });
    }
    resize() {
        this.texture = new Texture(Helpers.getAsset(this.getStageBackgroundKey()));
        this.scene.texture = this.texture;
        this.scene.leftWidth = this.texture.width / 2;
        this.scene.topHeight = 525;
        this.scene.rightWidth = this.texture.width / 2;
        this.scene.bottomHeight = this.texture.height - 525;
        this.scene.width = this.texture.width;
        this.scene.height = this.texture.height;
        const size = this.getReelSize();
        const rWidth = this.scene.width;
        const rHeight = size * Game.Engine.SETTINGS.GAME_SYMBOLS_PER_REEL;
        for (let i = 0; i < Number(Game.Engine.SETTINGS.GAME_REELS); i++) {
            const reel = Game.Engine.REELS[i];
            reel.setOffset(i);
            reel.setSize(rWidth, rHeight);
            reel.setContainerSize(i, rWidth);
            reel.setDividerSize(rHeight);
        }
        this.reelsView.position.x = this.scene.width / 2 - this.reelsView.width / 2;
        if (this.reelsView.mask) {
            this.reelsView.mask.width = this.reelsView.width / 0.82;
            this.reelsView.mask.height = this.reelsView.height / 0.82;
        }
        this.setMultipliersPosition();
    }
}
