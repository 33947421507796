var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import confetti from "canvas-confetti";
import { Helpers, Modal } from 'lama-games-engine';
import { Container, Point, SimpleRope, Text } from 'pixi.js';
import Game from "../starter";
const polarToCartesian = (centerX, centerY, radius, angleInDegrees) => {
    const angleInRadians = (angleInDegrees - 90) * Math.PI / 180.0;
    return {
        x: centerX + (radius * Math.cos(angleInRadians)),
        y: centerY + (radius * Math.sin(angleInRadians))
    };
};
class ModalContentWrapper extends Container {
}
class BaseWin {
    title(title, width) {
        const radius = this.titleRadius();
        const text = new Text(title, {
            fontFamily: 'Montserrat',
            fontSize: 150,
            fontWeight: 'bold',
            fill: '#eaece3',
            lineJoin: "round",
            dropShadow: true,
            dropShadowAlpha: 1,
            dropShadowAngle: 90,
            dropShadowBlur: 0,
            dropShadowColor: '#0a7cec',
            dropShadowDistance: 10
        });
        const points = [];
        for (let i = 0; i < 20; i++) {
            const y = i < 10 ? i : 20 - i;
            points.push(new Point(i * text.width / 20, y * 10));
        }
        const newPoints = [];
        for (let i = 0; i <= 20; i++) {
            const point = polarToCartesian(width / 2, 0, radius, ((30 * i) / 20) - 15);
            const pivotPoint = new Point(point.x, point.y);
            newPoints.push(pivotPoint);
        }
        return new SimpleRope(text.texture, newPoints);
    }
    titleRadius() {
        return 1400;
    }
    message(message) {
        const text = new Text(message, {
            fontFamily: 'Montserrat',
            fontSize: 120,
            fontWeight: 'bold',
            align: 'center',
            fill: '#ffc700',
            lineJoin: "round",
            dropShadow: true,
            dropShadowAlpha: 1,
            dropShadowAngle: 90,
            dropShadowBlur: 0,
            dropShadowColor: '#a102f2',
            dropShadowDistance: 10
        });
        text.anchor.set(0.5);
        text.position.set(text.width / 2, text.height / 2);
        return text;
    }
}
class TenFreeSpinsStars extends BaseWin {
    stars() {
        const container = new Container();
        const star = Helpers.getSpriteFromTextureURL('star.png');
        star.scale.set(0.6);
        star.anchor.set(0.5);
        star.position.set(star.width / 2, star.height / 2);
        container.addChild(star);
        container.pivot.set(container.width / 2, container.height / 2);
        container.position.set(container.width / 2, container.height / 2);
        return container;
    }
    starsOffset() {
        return 25;
    }
    background() {
        return Helpers.getSpriteFromTextureURL(`free-spins.png`);
    }
    title() {
        return super.title('FREE SPINS', this.background().width);
    }
    message(message) {
        return super.message('10');
    }
    confettiCount() {
        return 1;
    }
}
class HugeWin extends BaseWin {
    stars() {
        const container = new Container();
        const star = Helpers.getSpriteFromTextureURL('star.png');
        star.scale.set(0.6);
        star.anchor.set(0.5);
        star.position.set(star.width / 2, star.height / 2);
        container.addChild(star);
        container.pivot.set(container.width / 2, container.height / 2);
        container.position.set(container.width / 2, container.height / 2);
        return container;
    }
    starsOffset() {
        return 25;
    }
    background() {
        return Helpers.getSpriteFromTextureURL(`huge-win.png`);
    }
    title() {
        return super.title('HUGE WIN', this.background().width);
    }
    confettiCount() {
        return 1;
    }
}
class MegaWin extends BaseWin {
    stars() {
        const container = new Container();
        const first = Helpers.getSpriteFromTextureURL('star.png');
        first.scale.set(0.8);
        first.anchor.set(0.5);
        first.angle = -6;
        first.position.set(first.width / 2, first.height / 2);
        container.addChild(first);
        const second = Helpers.getSpriteFromTextureURL('star.png');
        second.scale.set(0.8);
        second.anchor.set(0.5);
        second.angle = 6;
        second.position.set(second.width / 2, second.height / 2);
        second.position.x += first.width + 50;
        container.addChild(second);
        container.pivot.set(container.width / 2, container.height / 2);
        container.position.set(container.width / 2, container.height / 2);
        return container;
    }
    starsOffset() {
        return 40;
    }
    background() {
        return Helpers.getSpriteFromTextureURL(`mega-win.png`);
    }
    title() {
        return super.title('MEGA WIN', this.background().width);
    }
    confettiCount() {
        return 2;
    }
}
class EpicWin extends BaseWin {
    stars() {
        const container = new Container();
        const first = Helpers.getSpriteFromTextureURL('star.png');
        first.scale.set(0.8);
        first.anchor.set(0.5);
        first.angle = -12;
        first.position.set(first.width / 2, first.height / 2);
        container.addChild(first);
        const second = Helpers.getSpriteFromTextureURL('star.png');
        second.scale.set(1);
        second.anchor.set(0.5);
        second.position.set(second.width / 2, second.height / 2);
        second.position.x = first.position.x + first.width + 20;
        second.position.y -= 85;
        container.addChild(second);
        const third = Helpers.getSpriteFromTextureURL('star.png');
        third.scale.set(0.8);
        third.anchor.set(0.5);
        third.angle = 12;
        third.position.set(third.width / 2, third.height / 2);
        third.position.x = second.position.x + second.width - 30;
        container.addChild(third);
        container.pivot.set(container.width / 2, container.height / 2);
        container.position.set(container.width / 2, container.height / 2);
        return container;
    }
    starsOffset() {
        return 100;
    }
    background() {
        return Helpers.getSpriteFromTextureURL(`epic-win.png`);
    }
    title() {
        return super.title('EPIC WIN', this.background().width);
    }
    confettiCount() {
        return 3;
    }
}
class WinFactory {
    static make(type) {
        return new this.map[type];
    }
}
WinFactory.map = {
    "huge": HugeWin,
    "mega": MegaWin,
    "epic": EpicWin,
    "10-free-spins": TenFreeSpinsStars
};
export class WinModal {
    constructor(type, options) {
        this.type = type;
        this.options = options;
        this.container = new ModalContentWrapper();
        this.modal = new Modal(this.options);
        this.win = WinFactory.make(this.type);
    }
    static presentModal(type, options) {
        return (new WinModal(type, options));
    }
    show() {
        var _a;
        return __awaiter(this, void 0, void 0, function* () {
            for (let i = 0; i < this.win.confettiCount(); i++) {
                setTimeout(() => {
                    confetti({
                        particleCount: window.PLATFORM.isMobile ? 500 : 300,
                        spread: 260,
                        scalar: window.PLATFORM.isMobile ? 1.3 : 2,
                    });
                }, 500 * i);
            }
            const background = this.win.background();
            const stars = this.win.stars();
            const title = this.win.title();
            const titleRadius = this.win.titleRadius();
            const message = this.win.message(`$${Game.Engine.Application.gameData.lastWin.toFixed(2)}`);
            this.container.addChild(background);
            this.container.addChild(stars);
            this.container.addChild(title);
            this.container.addChild(message);
            stars.position.set(background.width / 2, background.position.y + this.win.starsOffset());
            title.position.y = titleRadius
                + background.y
                + background.height
                + 50;
            message.position.set(this.container.width / 2, ((_a = title === null || title === void 0 ? void 0 : title.position.y) !== null && _a !== void 0 ? _a : 0) - titleRadius + 200);
            this.container.pivot.set(this.container.width / 2, 0);
            yield this.modal
                .addContentChild(this.container)
                .show();
        });
    }
    hide() {
        return __awaiter(this, void 0, void 0, function* () {
            //confetti.reset();
            yield this.modal.hide();
        });
    }
}
